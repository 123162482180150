/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingAppView, useChatwoot } from '@hospy/shared-components';
import { setAppName, useAppDispatch, useAppSelector } from '@hospy/store';
// import { setIntercomUser } from '@hospy/util-common';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import AppRoutes from '../routes/app-routes';

const App = () => {
	const { isAuth, user } = useAppSelector(({ user }) => user);
	// const { language } = useAppSelector(({ language }) => language);
	const { hiddeChatWoot } = useChatwoot();
	hiddeChatWoot();	
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setAppName('MAIN'));
	}, []);
	if (isAuth && !user) return <LoadingAppView />;
	// setIntercomUser(user);

	
	return (
		<>
			{/* {language === 'es' && (
				<Helmet>
					<title>
						Hospy - La herramienta para triunfar en el negocio de
						alquiler vacacional.
					</title>
					<meta
						name="description"
						content="Seguridad, cumplimiento legal y más ventas. Para property managers y anfitriones individuales."
					/>
					<meta
						content="Hospy - La herramienta para triunfar en el negocio de alquiler vacacional."
						property="og:title"
					/>
					<meta
						content="Seguridad, cumplimiento legal y más ventas. Para property managers y anfitriones individuales."
						property="og:description"
					/>
					<meta
						content="Hospy - La herramienta para triunfar en el negocio de alquiler vacacional."
						property="twitter:title"
					/>
					<meta
						content="Seguridad, cumplimiento legal y más ventas. Para property managers y anfitriones individuales."
						property="twitter:description"
					/>
				</Helmet>
			)}
			{language === 'en' && (
				<Helmet>
					<title>
						Hospy - The tool to succeed in the vacation rental
						business.
					</title>
					<meta
						name="description"
						content="Security, compliance and more sales. For property managers and individual hosts."
					/>
					<meta
						content="Hospy - The tool to succeed in the vacation rental business."
						property="og:title"
					/>
					<meta
						content="Security, legal compliance and more sales. For property managers and individual hosts."
						property="og:description"
					/>
					<meta
						content="Hospy - The tool to succeed in the vacation rental business."
						property="twitter:title"
					/>
					<meta
						content="Security, legal compliance and more sales. For property managers and individual hosts."
						property="twitter:description"
					/>
				</Helmet>
			)}
			{language === 'pt' && (
				<Helmet>
					<title>
						Hospy - A ferramenta para ter sucesso no negócio de
						aluguel por temporada.
					</title>
					<meta
						name="description"
						content="Segurança, conformidade e mais vendas. Para administradores de propriedades e anfitriões individuais."
					/>
					<meta
						content="Hospy - A ferramenta para ter sucesso no negócio de aluguel por temporada."
						property="og:title"
					/>
					<meta
						content="Segurança, conformidade legal e mais vendas. Para administradores de propriedades e anfitriões individuais."
						property="og:description"
					/>
					<meta
						content="Hospy - A ferramenta para ter sucesso no negócio de aluguel por temporada."
						property="twitter:title"
					/>
					<meta
						content="Segurança, conformidade legal e mais vendas. Para administradores de propriedades e anfitriões individuais."
						property="twitter:description"
					/>
				</Helmet>
			)} */}
			<React.Suspense fallback={<LoadingAppView />}>
				<AnimatePresence>
					<AppRoutes />
				</AnimatePresence>
			</React.Suspense>
		</>
	);
};

export default App;
